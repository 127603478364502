<template>
  <v-container class="px-0 py-0 main-wrapper">
    <v-row class="d-flex flex-row">
      <v-col cols="7" class="d-flex justify-start align-center pl-0">
        <span class="custom-styling">{{ t('earliest_delivery_date') }}</span>
      </v-col>
      <v-col cols="5" class="d-flex justify-end align-center pr-0">
        <v-btn
          elevation="0"
          variant="outlined"
          class="show-date"
          :class="{ active: userSetEarliestPossibleDeliveryDate }"
          :loading="isLoading"
          max-width="100%"
          @click="handleSetDeliveryDateToEarliestPossible">
          <template v-if="!isLoading">
            {{ earliestPossibleDeliveryDate?.toLocaleDateString('de-DE', DateStringOptions) }}
          </template>
        </v-btn>
      </v-col>
    </v-row>

    <v-divider class="footer-divider mt-2 mb-1" />

    <v-container v-if="!isLoading" class="px-0 first-part">
      <v-local-provider class="d-flex justify-center" :locale="locale">
        <v-date-picker
          ref="datePicker"
          v-model="selectedDateRange"
          color="secondary"
          hide-header
          width="100%"
          :allowed-dates="getAllowedDates"
          :min="getMinDate"
          show-adjacent-months
          multiple="range"
          :max="new Date(nextYear, 11, 31)"
          @update:model-value="setDateRange" />
      </v-local-provider>
      <v-divider class="footer-divider my-2" />

      <v-row>
        <v-col cols="12" class="d-flex flex-column justify-center text-center pb-0 px-0">
          <span class="font-weight-bold">{{ t('placement_time_window') }}</span>
        </v-col>
        <v-col cols="12" class="px-0">
          <v-row class="pb-3 px-0">
            <v-col class="pt-0 px-0" cols="12">
              <v-radio-group
                v-model="payload.containerPlacementTime"
                class="d-flex justify-center time-radio"
                hide-details
                inline>
                <v-radio
                  color="primary"
                  class="py-2 px-4"
                  :label="CONTAINER_DELIVERY_TIME_FRAME.WHOLE_DAY"
                  :value="CONTAINER_DELIVERY_TIME_FRAME.WHOLE_DAY" />
                <v-radio
                  color="primary"
                  class="py-2 px-4"
                  :label="CONTAINER_DELIVERY_TIME_FRAME.MORNING"
                  :value="CONTAINER_DELIVERY_TIME_FRAME.MORNING" />
                <v-radio
                  color="primary"
                  class="py-2 px-4"
                  :label="CONTAINER_DELIVERY_TIME_FRAME.AFTERNOON"
                  :value="CONTAINER_DELIVERY_TIME_FRAME.AFTERNOON" />
              </v-radio-group>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <v-container v-else>
      <v-skeleton-loader type="list-item-three-line" />
      <v-skeleton-loader type="list-item-three-line" />
      <v-skeleton-loader type="list-item-three-line" />
    </v-container>

    <v-container class="px-0">
      <CdeCard color="secondary" rounded="lg">
        <v-card-text class="custom-info-box">
          <p class="font-weight-bold">{{ t('please_be_advised') }}</p>
          <p>
            <span class="font-weight-bold">{{ t('fourteen_days_duration_free') }}</span>
            {{ t('is_included_however') }}
            <span class="font-weight-bold">{{ t('every_week_price') }}</span>
            {{ t('rent_fee_charge') }}
          </p>
        </v-card-text>
      </CdeCard>

      <v-row class="pt-6">
        <v-col cols="12" class="px-0">
          <v-tooltip top :disabled="!incomplete">
            <template #activator="{ props }">
              <div v-bind="props">
                <ButtonPrimary width="100%" @click="confirmData">
                  {{ t('carry_over') }}
                </ButtonPrimary>
              </div>
            </template>
            <span>{{ t('please_complete_date_selection') }}</span>
          </v-tooltip>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script setup lang="ts">
import { useDateUtils } from '@/composables/useDateUtils'
import { CONTAINER_DELIVERY_TIME_FRAME, DateStringOptions } from '@/types/enums'
import type { OrderDatePayload } from '@/types/types'
import { useDate } from 'vuetify'
const adapter = useDate()

const { locale, t } = useI18n()
const { getEarliestPossibleDeliveryDate, isWeekday, isPublicHoliday } = useDateUtils()

const dateStore = useDateStore()

const sidebarStore = useSidebarStore()
const properties = defineProps<{
  selectedDates: Date[]
  selectedPlacementTime: CONTAINER_DELIVERY_TIME_FRAME
}>()
const { selectedDates, selectedPlacementTime } = toRefs(properties)
const isLoading = ref(true)
const selectedDateRange = ref<Date[]>(selectedDates.value)
const earliestPossibleDeliveryDate = ref<Date | null>(null)
const userSetEarliestPossibleDeliveryDate = ref(false)
const payload = ref<OrderDatePayload>({
  deliveryDate: [],
  containerPlacementTime: selectedPlacementTime.value,
})
const datePicker = ref()
const nextYear = new Date().getFullYear() + 1

function handleSetDeliveryDateToEarliestPossible() {
  if (!earliestPossibleDeliveryDate.value) return

  selectedDateRange.value = [earliestPossibleDeliveryDate.value]

  userSetEarliestPossibleDeliveryDate.value = true

  selectedDateRange.value = [earliestPossibleDeliveryDate.value]
  manageTooltip()
}
function setDateRange(dateRange: Date[]) {
  payload.value.deliveryDate = dateRange

  if (
    selectedDateRange.value.length > 0 &&
    earliestPossibleDeliveryDate.value &&
    (selectedDateRange.value[0].toISOString().split('T')[0] !==
      earliestPossibleDeliveryDate.value.toISOString().split('T')[0] ||
      !dateRange.includes(earliestPossibleDeliveryDate.value))
  ) {
    userSetEarliestPossibleDeliveryDate.value = false
  }
  manageTooltip()
}

function manageTooltip() {
  const currentTooltip = document.querySelector('.custom-tooltip')
  if (selectedDateRange.value.length === 1) {
    if (currentTooltip) currentTooltip.remove()
    const startDate = selectedDateRange.value[0]
    const element = Array.from(
      datePicker.value?.$el.getElementsByClassName('v-date-picker-month__day'),
    ).find(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (el: any) => el.attributes.getNamedItem('data-v-date')?.value === adapter.toISO(startDate),
    ) as HTMLElement
    const tooltip = element.appendChild(document.createElement('div'))
    tooltip.classList?.add('custom-tooltip')
    tooltip.classList?.add('text-center')
    tooltip.appendChild(document.createElement('span')).textContent = t('now_select_pickup_day')
  } else {
    if (currentTooltip) currentTooltip.remove()
  }
}
function getAllowedDates(val: unknown) {
  if (!(val instanceof Date)) return false

  return isWeekday(val) && !isPublicHoliday(val)
}

const getMinDate = computed(() => {
  return earliestPossibleDeliveryDate.value &&
    earliestPossibleDeliveryDate.value.getTime() > Date.now()
    ? earliestPossibleDeliveryDate.value
    : new Date()
})

const emit = defineEmits(['datesConfirmed'])

const confirmData = () => {
  if (
    !payload.value.deliveryDate.length ||
    payload.value.deliveryDate.length < 2 ||
    !payload.value.containerPlacementTime ||
    payload.value.containerPlacementTime === null
  )
    return
  emit('datesConfirmed', payload.value)
  sidebarStore.closeSidebar()
}

const incomplete = computed(() => {
  return (
    !payload.value.deliveryDate.length ||
    payload.value.deliveryDate.length < 2 ||
    !payload.value.containerPlacementTime ||
    payload.value.containerPlacementTime === null
  )
})

onNuxtReady(async () => {
  await dateStore.getPublicHolidaysFromApi()

  earliestPossibleDeliveryDate.value = await getEarliestPossibleDeliveryDate()

  isLoading.value = false
})
</script>

<style lang="scss" scoped>
div:deep(.v-date-picker-month) {
  padding: 0;
}
:deep(.v-label--clickable) {
  max-width: 80px;
  text-align: center;
  font-size: 12px;
  font-weight: 700;
  color: #000;
}
:deep(.v-selection-control--inline) {
  display: flex;
  flex-direction: column !important;
}

:deep(.v-date-picker-controls__month .v-btn--density-default.v-btn--icon.v-btn--variant-text) {
  border: black 1px solid;
}

.custom-styling {
  font-size: 14px;
  font-weight: 700;
  color: #000;
  margin-top: 10px;
  margin-bottom: 10px;
}

.custom-info-box {
  font-size: 12px;
}

.footer-divider {
  border: 1px solid rgb(var(--v-theme-border));
}

.show-date {
  border: rgb(var(--v-theme-text)) 1px solid;
  color: rgb(var(--v-theme-text));

  &.active {
    color: rgb(var(--v-theme-primary));
    border: rgb(var(--v-theme-primary)) 1px solid;
  }
}
div:deep(.custom-tooltip) {
  position: absolute;
  z-index: 10001 !important;
  width: 160px;
  font-size: 12px;
  background-color: white;
  border-radius: 10px;
  padding: 4px;
  border: 1px solid rgb(var(--v-theme-border));
  top: -182%;
  left: -145%;
  overflow: visible;
}

div:deep(.v-date-picker-month__day--week-start .custom-tooltip) {
  left: -20%;
}
div:deep(.v-date-picker-month__day--week-start .custom-tooltip:before) {
  left: 15%;
}
div:deep(.v-date-picker-month__day-btn.v-btn--disabled) {
  background-color: rgb(var(--v-theme-surface-darken2)) !important;
  color: rgb(var(--v-theme-text)) !important;
}

div:deep(.custom-tooltip::before) {
  content: '';
  position: absolute;
  transform: rotate(45deg);
  top: 93%;
  left: 45%;
  background-color: white;
  border-bottom: 1px solid rgb(var(--v-theme-border));
  border-right: 1px solid rgb(var(--v-theme-border));
  padding: 5px;
  z-index: 1;
}
.v-tooltip,
.v-tooltip * {
  z-index: 10001 !important;
}
.main-wrapper {
  height: 85vh;
}
.first-part {
  max-height: 70%;
  overflow-y: auto;
}
.time-radio :deep(label) {
  font-weight: 300 !important;
  color: black !important;
  opacity: 1 !important;
  max-width: 76px;
}
@media (max-height: 815px) {
  .first-part {
    max-height: 65%;
  }
}
@media screen and (max-width: 599px) {
  .main-wrapper {
    height: 80vh;
  }
  .first-part {
    max-height: 40vh;
  }
  div:deep(.v-radio-group .v-input__control) {
    width: 100% !important;
  }
  div:deep(.v-selection-control-group) {
    justify-content: space-around !important;
  }
  div:deep(.v-radio) {
    padding: 8px 2px !important;
  }
}
</style>
